.table {
  min-width: 200px;
  width: 100%;

  thead > tr,
  tbody > tr {
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 4fr;
  }

  th {
    padding: 1rem 0;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.875rem;
    color: #808080;
  }

  thead {
    tr {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e5e5e5;

      th {
        padding: 1.5rem 0;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.125rem;
        color: #01314a;
      }

      td {
        padding: 1.5rem 0;
      }
    }

    .heavy_cell {
      padding-top: 1.25rem;
      text-align: center;
    }

    .removed_cell {
      text-align: center;
      line-height: 0.875rem;
    }

    .quantity_cell {
      display: flex;
      justify-content: center;
      padding: 0;
      padding-top: 0.42rem;
    }
  }
}
.red_star {
  color: #f94550;
}

.disclaimer {
  display: flex;
  padding: 0 2rem;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1rem;

  div {
    margin-right: 1rem;
  }

  div:last-child {
    margin-right: 0;
  }
}

.estimated_weight,
.entitlement_allowance {
  .label {
    padding-bottom: 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
  }

  .amount,
  .measure {
    line-height: 1.2rem;
    font-weight: 600;
  }

  .measure {
    font-size: 0.7rem;
    vertical-align: top;
  }

  .amount {
    display: inline-block;
    margin-right: 0.25rem;
    font-size: 2.5rem;
  }
}

.entitlement_allowance {
  color: #808080;
}

.estimation_container {
  text-align: center;

  circle {
    stroke-width: 2.5px;
  }

  .estimated_weight {
    margin-bottom: 0.6rem;
  }

  .entitlement_allowance {
    .label {
      padding: 0;
      font-size: 0.6rem;
      font-weight: bold;
      letter-spacing: 0.03rem;
      line-height: 0.7rem;
    }

    .amount,
    .measure {
      line-height: 0.8rem;
    }

    .measure {
      font-size: 0.6rem;
      vertical-align: middle;
    }

    .amount {
      display: inline-block;
      margin-right: 0.25rem;
      font-size: 1rem;
    }
  }
}

.overage {
  .overage_context {
    margin-bottom: 1rem;
  }

  .overage_warning {
    display: flex;
    text-align: left;
    padding: 1.2rem;
    font-size: 14px;
    line-height: 1.4rem;
    background-color: rgba(249, 69, 80, 0.12);
    border: 1px solid rgba(234, 22, 22, 0.12);
    border-radius: 8px;
    color: #ad1919;
    svg {
      margin-right: 14px;
    }
  }
}

.within_entitlement {
  margin-top: 1rem;

  .within_entitlement_content {
    display: flex;
    text-align: left;
    padding: 1.2rem;
    font-size: 14px;
    line-height: 1.375rem;
    background-color: #def0eb;
    border: 1px solid rgba(0, 152, 116, 0.12);
    border-radius: 8px;
    color: #115b42;
    svg {
      margin-right: 14px;
    }
  }
}

.action_row {
  background-color: white;

  .action_container {
    display: flex;
    padding: 1rem 0;

    .removedItemsAlert {
      position: relative;
      z-index: 10100;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 38px;
      padding: 0 1rem;
      background: #fff7ec;
      border: 1px solid #f2a328;
      border-radius: 8px;

      .removedItemsAlertText {
        margin-right: 1.5rem;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: #01314a;
      }

      .removedItemsAlertLink {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
}

.weight_break_down {
  p {
    text-align: left;
    display: flex;
    justify-content: space-between;

    span:last-child {
      text-align: right;
    }

    br {
      display: none;
      @media (max-width: 1440px) {
        display: inherit;
      }
    }
  }
}

@media (min-width: 992px) {
  .mini_wizard {
    display: none;
  }
}

@media (max-width: 992px) {
  .mini_wizard {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5.35rem;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 3px 9px rgb(0 0 0 / 10%);
    grid-template-columns: 3.25rem max-content max-content;
    grid-column-gap: 1rem;

    circle {
      stroke-width: 5px;
    }

    .estimated_weight {
      margin: 0;
    }
  }

  .disclaimer {
    padding: 0;
  }
}

.weightWizardPanel > div {
  display: block !important;
}

.restoreButton {
  background: none;
  border: none;
}
